import { Stack } from '@mui/material';
import React from 'react';
import ItemCategory from './ItemCategory';

function AllItemsView(props) {
    return (
        <Stack spacing={2}>
            { Object.keys(props.review.items).map((itemCategory) => (
                <ItemCategory 
                    key={itemCategory} 
                    categoryName={itemCategory} 
                    items={props.review.items[itemCategory]} 
                    review={props.review}
                    handleUpdate={props.handleUpdate}
                    isReadOnly={props.isReadOnly}
                />
            ))}
        </Stack>
    );
}

export default AllItemsView;
