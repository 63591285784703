import React, { useMemo } from 'react';
import { Toolbar, Divider, List, ListItemButton, ListItemIcon, ListItemText, Skeleton } from '@mui/material';
import { ChecklistRounded, Inventory2Rounded, SupportRounded } from '@mui/icons-material';
import logo from '../../../assets/docsi_logo.svg';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useStaffReviewUser from '../Hooks/useStaffReviewUser';
import { useCreateDraftCheckoutLogMutation } from '../../api/staffReviewSlice';

function StaffReviewSidebarContents() {
    const navigate = useNavigate();
    const location = useLocation();
    const { staffReviewUser } = useStaffReviewUser();
    const { draftId } = useParams();
    const [createCheckoutLog] = useCreateDraftCheckoutLogMutation();

    async function handleNavigate(path) {
        if (draftId) {
            await createCheckoutLog({ draftId: draftId, action: 'checked_in', reason: 'navigated_away' });
        }

        if (location.pathname.includes('v2')) {
            navigate('/staffReview/v2' + path);
        } else {
            navigate('staffReview' + path);
        }
    }

    const selectedIndex = useMemo(() => {
        if (location.pathname.split('/').includes('activeReviews')) {
            return 0;
        } else if (location.pathname.split('/').includes('pastReviews')) {
            return 1;
        }
    }, [location]);

    return (
        <>
            <Toolbar sx={{ padding: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                { staffReviewUser.organization ? <img src={staffReviewUser.organization.logo ? staffReviewUser.organization.logo : logo} alt='Site Logo' style={{ objectFit: 'contain' }} width={200}/> : <Skeleton variant='rectangle' width={150} height={20} /> }
            </Toolbar>
            <Divider />
            <List style={{ paddingTop: '0' }}>
                <ListItemButton key={0} selected={selectedIndex === 0} onClick={() => handleNavigate('/activeReviews')}>
                    <ListItemIcon>
                        <ChecklistRounded />
                    </ListItemIcon>
                    <ListItemText primary='Active Reviews' />
                </ListItemButton>
                <Divider />
                <ListItemButton key={1} selected={selectedIndex === 1} onClick={() => handleNavigate('/pastReviews')}>
                    <ListItemIcon>
                        <Inventory2Rounded />
                    </ListItemIcon>
                    <ListItemText primary='Completed Reviews' />
                </ListItemButton>
                <Divider />
                <ListItemButton target="_blank" href = "https://docs.google.com/forms/d/e/1FAIpQLSdZk2yXi-G7yU_dhUurqcd_id2ffgXYXh46m0tNwW2EJJmD-Q/viewform" key={2} selected={selectedIndex === 2}>
                    <ListItemIcon>
                        <SupportRounded />
                    </ListItemIcon>
                    <ListItemText primary='Need Help?' />
                </ListItemButton>
                <Divider />
            </List>
        </>
    );
}

export default StaffReviewSidebarContents;
