import { Table, TableCell, TableContainer, TableHead } from '@mui/material';
import React, { useMemo } from 'react';
import { theme } from '../../../theme';
import StaffReviewItemEntry from './StaffReviewItemEntry';

function StaffReviewChangesOnlyView(props) {
    const combinedChanges = useMemo(() => {
        const combined = [];

        Object.keys(props.review.recommendations ?? {}).forEach((category) => {
            combined.push(...props.review.recommendations[category].filter((rec) => {
                return rec.review.length > 0 && (rec.status === 'accepted' || rec.status === 'custom_removal' || rec.status === 'custom_update' || rec.status === 'custom_swap');
            }));
        });

        return combined;
    }, [props.review]);

    return (
        <TableContainer style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            border: '1px solid',
            borderColor: theme.palette.border.main,
        }}>
            <Table>
                <TableHead>
                    <TableCell style={{ border: 'none' }} />
                    <TableCell style={{ border: 'none' }}><strong>Item</strong></TableCell>
                    <TableCell style={{ border: 'none' }}><strong>Requested Optimization</strong></TableCell>
                    <TableCell style={{ textAlign: 'center', border: 'none' }}><strong>Open</strong></TableCell>
                    <TableCell style={{ textAlign: 'center', border: 'none' }}><strong>Hold</strong></TableCell>
                    <TableCell style={{ textAlign: 'center', border: 'none' }}><strong>New Open</strong></TableCell>
                    <TableCell style={{ textAlign: 'center', border: 'none' }}><strong>New Hold</strong></TableCell>
                    <TableCell style={{ textAlign: 'end', border: 'none' }}><strong>Review Status</strong></TableCell>
                </TableHead>
                { combinedChanges.map((rec, index) => {
                    return (
                        <StaffReviewItemEntry
                            key={rec.id}
                            index={index}
                            item={props.review.items[rec.item.category]?.find((item) => item.id === rec.item.id)}
                            recommendation={rec}
                            review={rec.review[0]}
                            handleUpdate={props.handleUpdate}
                            disabled={props.isReadOnly || rec.review[0].submitted_at !== null}
                        />
                    );
                })}
            </Table>
        </TableContainer>
    );
}

export default StaffReviewChangesOnlyView;
