import { ArrowForwardRounded, ExpandLess, ExpandMore } from '@mui/icons-material';
import { TableRow, TableCell, Collapse, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { theme } from '../../../theme';
import StaffReviewItemDetails from './StaffReviewItemDetails';
import ActionDropdown from './ActionDropdown';
import OverdueChip from './OverdueChip';
import ReviewerFeedback from './ReviewerFeedback';
import { parseISO, format } from 'date-fns';

function StaffReviewItemEntry(props) {
    const [detailsOpen, setDetailsOpen] = useState(false);

    const feedbackOpen = useMemo(() => {
        return props.review?.status === 'rejected' || detailsOpen;
    }, [props.review, detailsOpen]);

    function handleItemClick() {
        setDetailsOpen(!detailsOpen);
    }

    const recTypeText = {
        addition: 'Add to Card',
        removal: 'Remove from Card',
        update: 'Quantity Change',
        swap: <Stack direction='row' alignItems='center'>Swap to New Item <ArrowForwardRounded size='small' style={{ paddingLeft: '8px', color: theme.palette.subtext.main }}/></Stack>,
        compliance: <Stack direction='row' alignItems='center'>Swap to New Item <ArrowForwardRounded size='small' style={{ paddingLeft: '8px', color: theme.palette.subtext.main }}/></Stack>,
    };

    return (
        <>
            <TableRow style={{ borderTop: '1px solid', borderColor: theme.palette.border.main }} >
                <TableCell style={{ cursor: 'pointer' }} onClick={handleItemClick}>
                    { detailsOpen ? 
                        <ExpandLess style={{ color: theme.palette.subtext.main, cursor: 'pointer' }} /> :
                        <ExpandMore style={{ color: theme.palette.subtext.main, cursor: 'pointer' }} />
                    }
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} onClick={handleItemClick}>{props.item.name}</TableCell>
                <TableCell>
                    {props.recommendation ? recTypeText[props.recommendation.type] : 'None'}
                </TableCell>
                { props.recommendation?.type !== 'swap' && props.recommendation?.type !== 'compliance' &&
                    <>
                        <TableCell style={{ textAlign: 'center' }}>
                            {props.item?.open_quantity ?? '-'}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                            {props.item?.hold_quantity ?? '-'}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                            {props.recommendation?.open_quantity ?? '-'}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                            {props.recommendation?.hold_quantity ?? '-'}
                        </TableCell>
                    </>
                }
                { (props.recommendation?.type === 'swap' || props.recommendation?.type === 'compliance') &&
                    <TableCell style={{ textAlign: 'left' }} colSpan={4}>
                        <Typography variant='normal' fontSize='14px'><strong>New Item: </strong>{props.recommendation?.recommended_item.name}</Typography>
                    </TableCell>
                }
                <TableCell style={{ padding: 0, textAlign: 'end' }}>
                    { props.review && 
                        <Stack direction='row' alignItems='center' justifyContent='flex-end'>
                            { props.review.is_overdue && props.review.status === 'open' &&
                                <OverdueChip />
                            }
                            <ActionDropdown 
                                review={props.review} 
                                recommendation={props.recommendation} 
                                disabled={props.disabled} 
                                handleUpdate={props.handleUpdate} 
                            />
                        </Stack>
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={feedbackOpen} timeout="auto" unmountOnExit>
                        <Stack direction='row' p={2} spacing={2} alignItems='center' justifyContent='flex-end' backgroundColor={theme.palette.background.darkGray}>
                            { props.review && props.review.status === 'rejected' && 
                                <ReviewerFeedback 
                                    review={props.review} 
                                    recommendation={props.recommendation} 
                                    handleUpdate={props.handleUpdate} 
                                    disabled={props.disabled}
                                />
                            }
                            { props.review && props.review.reviewed_at !== null && 
                                <Typography fontSize='12px' minWidth='fit-content'><strong>Reviewed by: </strong> {props.review.editor?.first_name} {props.review.editor?.last_name}, {format(parseISO(props.review.reviewed_at), 'M/d/yy')} </Typography>
                            }
                        </Stack>
                    </Collapse>
                    <Collapse in={detailsOpen} timeout="auto" unmountOnExit>
                        <StaffReviewItemDetails item={props.item} recommendation={props.recommendation} review={props.review} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default StaffReviewItemEntry;

