import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { theme } from '../../../theme';
import PhysicianEntry from './PhysicianEntry';

function PhysicianGroups(props) {
    return (
        <Stack width='100%' divider={<Divider />} sx={{
            border: '1px solid',
            borderRadius: '8px',
            borderColor: theme.palette.border.main,
            backgroundColor: 'white',
        }}>
            { Object.keys(props.draftGroupings).length === 0 &&
                <Typography p={2} variant='normal' sx={{ fontSize: '16px' }}>No cards to review at this time. Check back again later!</Typography>
            }
            { Object.keys(props.draftGroupings).map((physicianId) => {
                return <PhysicianEntry 
                    key={physicianId} 
                    physician={props.draftGroupings[physicianId].physician} 
                    drafts={props.draftGroupings[physicianId].drafts} 
                    status={props.status}
                />
            })}
        </Stack>
    )
}

export default PhysicianGroups;
