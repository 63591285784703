import { ChevronRight } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../../theme';
import OverdueChip from '../../CampaignMonitoring/OverdueChip';
import { useNavigate } from 'react-router-dom';

function CardEntry(props) {
    const navigate = useNavigate();

    return (
        <Stack 
            direction='row'
            alignItems='center' 
            justifyContent='space-between'
            onClick={() => navigate(`/staffReview/v2/${props.draft.id}`)}
            p={2}
            style={{
                cursor: 'pointer'
            }}
        >
            <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='normal' fontSize='16px'>{props.draft.card.name}</Typography>
                { props.draft.num_overdue_reviews > 0 &&
                    <OverdueChip format='staff_review_count' numOverdue={props.draft.num_overdue_reviews} />
                }
            </Stack>
            <Stack direction='row' spacing={1} alignItems='center'>
                { (props.draft.status === 'review' || props.draft.status === 'submitted') && 
                    <>
                        <Typography color={theme.palette.blue.main} variant='normal' fontSize='16px'>{props.draft.num_recommendations_to_review} Request{props.draft.num_recommendations_to_review !== 1 && 's'} to Review</Typography>
                        <ChevronRight style={{ color: theme.palette.blue.main }} />
                    </>
                }
                { props.draft.status === 'applied' && 
                    <>
                        <Typography color='black' variant='normal' fontSize='16px'>{props.draft.num_recommendations_to_review} Request{props.draft.num_recommendations_to_review !== 1 && 's'} Reviewed</Typography>
                        <ChevronRight />
                    </>
                }
            </Stack>
        </Stack>
    );
}

export default CardEntry;
