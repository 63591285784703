import { Stack } from '@mui/material';
import React, { useState, useMemo, useEffect } from 'react';
import StaffReviewCardHeader from './StaffReviewCardHeader';
import StaffReviewChangesOnlyView from './StaffReviewChangesOnlyView';
import { useGetReviewQuery, useUpdateReviewMutation, useLogUserActionMutation, useUpdateDraftMutation, useCreateDraftCheckoutLogMutation } from '../../../api/staffReviewSlice';
import useStaffReviewUser from '../../Hooks/useStaffReviewUser';
import { useNavigate, useParams } from 'react-router-dom';
import { differenceInMinutes } from 'date-fns';
import SubmitReviewConfirmation from './SubmitReviewConfirmation';
import CheckedOutCardDialog from '../CheckedOutCardDialog';
import CheckoutTimeoutDialog from '../CheckoutTimeoutDialog';
import AllItemsView from './AllItemsView';


function StaffReviewCardView() {
    const { draftId } = useParams();
    const { data: review = {}, isSuccess: reviewLoaded } = useGetReviewQuery(draftId);
    const { staffReviewUser: user } = useStaffReviewUser();
    const [updateReview] = useUpdateReviewMutation();
    const [updateDraft] = useUpdateDraftMutation();
    const [createCheckoutLog] = useCreateDraftCheckoutLogMutation();
    const [logUserAction] = useLogUserActionMutation();
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [timeOfLastUpdate, setTimeOfLastUpdate] = useState(new Date());
    const [isShowingSubmitConfirmation, setIsShowingSubmitConfirmation] = useState(false);
    const [isShowingCheckedOutDialog, setIsShowingCheckedOutDialog] = useState(false);
    const [isShowingTimeoutDialog, setIsShowingTimeoutDialog] = useState(false);
    const navigate = useNavigate();
    
    const [activeView, setActiveView] = useState('changesToReview');

    useEffect(() => {
        if (reviewLoaded) {
            if (review.status === 'applied') {
                setIsReadOnly(true);
                return;  // If the review is already applied, we don't need to check for checkout status since it will always be readonly. 
            }

            const checkoutStatus = review.checkout;
            if (!checkoutStatus) {
                checkoutReview();
                return;
            }

            const currentTime = new Date();
            const timeSinceLastAction = differenceInMinutes(currentTime, new Date(review.last_staff_review_action_time));
            const timeSinceCheckout = differenceInMinutes(currentTime, new Date(checkoutStatus.time_of_action));
             
            if (checkoutStatus.action === 'checked_out' && checkoutStatus.reviewer.id !== user.id && (timeSinceLastAction < 15 || timeSinceCheckout < 15)) {
                setIsShowingCheckedOutDialog(true);
                logAction('user_checkout_blocked', { draftId: draftId });
                setIsReadOnly(true);
            } else if (checkoutStatus.action === 'checked_in') {
                checkoutReview();
            } else if (checkoutStatus.action === 'checked_out' && timeSinceLastAction >= 15 && timeSinceCheckout >= 15) {
                checkoutReview();
            }
        }
    }, [review, reviewLoaded]);

    const canSubmit = useMemo(() => {
        if (isReadOnly) {
            return false;
        }

        return Object.keys(review.items ?? {}).every((key) => {
            if (!review.recommendations[key]) {
                return true;
            }
            return review.recommendations[key]?.every((rec) => {
                if (rec.review.length > 0) {
                    return rec.review[0].status === 'accepted' || rec.review[0].status === 'staff_implemented' || (rec.review[0].status === 'rejected' && rec.review[0].notes);
                }
                return true;
            });
        });
    }, [review.items, review.recommendations, isReadOnly]);

    async function logAction(action, data) {
        await logUserAction({
            action: action,
            data: data
        });
    }

    async function checkoutReview() {
        await createCheckoutLog({
            draftId: draftId,
            action: 'checked_out'
        });
    }

    async function handleUpdate(review, recommendation, newStatus, notes) {
        if (differenceInMinutes(new Date(), timeOfLastUpdate) >= 15) {
            setIsShowingTimeoutDialog(true);
            logAction('user_checkout_timed_out', { draftId: draftId });
            return;
        }

        await updateReview({
            review: review,
            draftId: draftId,
            recommendation: recommendation,
            newStatus: newStatus,
            notes: notes
        }).unwrap().then(() => {
            setTimeOfLastUpdate(new Date());
        });
    }

    async function handleSubmitReview() {
        await updateDraft({
            draftId: review.id,
            status: 'applied'
        }).unwrap().then(() => {
            createCheckoutLog({
                draftId: draftId,
                action: 'checked_in',
                reason: 'review_submitted',
            });
        });
        setIsShowingSubmitConfirmation(false);
    }

    return (
        <Stack py={2} px={4} spacing={2}>
            { reviewLoaded && 
                <>
                    <StaffReviewCardHeader 
                        activeView={activeView} 
                        setActiveView={setActiveView} 
                        canSubmit={canSubmit} 
                        review={review} 
                        handleUpdate={handleUpdate}
                        setIsShowingSubmitConfirmation={setIsShowingSubmitConfirmation}
                    />
                    { activeView === 'changesToReview' && 
                        <StaffReviewChangesOnlyView 
                            review={review} 
                            handleUpdate={handleUpdate}
                            isReadOnly={isReadOnly}
                        />
                    }
                    { activeView === 'allCardItems' &&
                        <AllItemsView 
                            review={review} 
                            handleUpdate={handleUpdate}
                            isReadOnly={isReadOnly}
                        />
                    }
                    <SubmitReviewConfirmation 
                        open={isShowingSubmitConfirmation} 
                        handleClose={() => setIsShowingSubmitConfirmation(false)} 
                        handleSubmit={handleSubmitReview} 
                    />
                    <CheckedOutCardDialog 
                        draft={review}
                        open={isShowingCheckedOutDialog} 
                        handleClose={() => setIsShowingCheckedOutDialog(false)} 
                        returnToActiveReviews={() => navigate('/staffReview/v2/activeReviews')} 
                    />
                    <CheckoutTimeoutDialog
                        draft={review}
                        open={isShowingTimeoutDialog}
                        handleRefresh={() => window.location.reload()}
                    />
                </>
            }
        </Stack>
    );
}

export default StaffReviewCardView;
